// Mixin Cheat Sheet:

// $xs: ( max:  767px );
// $sm: ( min:  768px );
// $md: ( min:  992px );
// $lg: ( min: 1200px );
// $sm-only: ( min: map-get($sm, min), max: map-get($md, min) - 1 );
// $md-only: ( min: map-get($md, min), max: map-get($lg, min) - 1 );

/* General
----------------------------------------------------------*/

// Custom Breakpoints
$xs-only: (min: 0, max: 766px);

// Custom Variables
$diagonal-bg: url(../images/light-diagonal-pattern-alpha.png);

// Logo
/*.brand__lockup {
  margin-top: 30px;
  a img {
    height: 90px;
  }
}*/

// Menu
/*#primary-nav .level-1 {
	width: 20%;
}*/

// Menu customizations
@include breakpoint($md) {
  #page #drawer-nav {
    // Adjustments drawer navigation layout.
    > .menu > li {
      > ul {
        > li {
          list-style-type: none;
          width: 33%;
          padding-right: 10px;
          min-height: 50px;
        }
      }
    }
  }
}

// Site Name Component
.site-name-wrapper {
  text-align: center;

  @include breakpoint($md) {
    padding: 15px;
  }
}

.youtube-callout-wrapper {
  text-align: center;
  border-top: 1px solid #ddd;
  padding: 15px 20px 10px;
  margin-top: 15px;

  @include breakpoint($sm) {
    margin-top: 10px;
  }

  p {
    font-size: 13px;
    line-height: 18px;
  }

  a.external-link:after {
    content: '';
  }

  .cta-icon {
    width: 48px;
    height: 48px;
    fill: $wcm-white;
  }
}

// Footer Navigation
.footer-nav {
  width: 100%;
  margin-bottom: 20px;

  .block-menu-block .content {
    column-count: 4;
    column-gap: 15px;
  }
}

// Grid Helpers
.no-gutter {
  margin-right: 0;
  margin-left: 0;
}

.no-gutter > [class*='col-'] {
  padding-right: 0;
  padding-left: 0;
}

// Icons
.cta-icon, .std-icon {
  color: $wcm-dark-orange;
  fill: $wcm-bright-orange;
}

.btn-sml {
  background-color: $wcm-dark-orange;
  color: #fff;
  border-radius: 10px;
  height: 30px;
  line-height: 28px;
  padding-bottom: 0;
  padding-top: 0;
  font-size: 13px;
}

// Carousel fix
@include breakpoint($sm-only) {
  #thumbnail-pager a>img {
    width: 90px;
    height: 36px;
  }

  .thumbnail-controller .pager {
    margin: 9px 0;
  }
}


// Callouts
.callouts-a-wrapper {
  margin: 40px 0;
}

.callout-a {
  height: 215px;
  display: block;
  padding-top: 75px;
  padding-right: 10px;

  h3 {
    text-align: left;
    padding-left: 40px;
    color: #fff;
    font-size: 3rem;
  }

  &:before {
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 25px 0 25px 20px;
    border-color: transparent transparent transparent #fff;
    position: absolute;
    top: 85px;
  }

  &.two-line {
    padding-top: 60px;
  }

  &:hover {
    &:before {
      border-color: transparent transparent transparent #fff;
    }
  }

  @include breakpoint($xs-only) {
    height: 180px;
    padding-top: 60px;

    &:before {
      top: 68px;
    }

    h3 {
      font-size: 2.4rem;
    }

    &.two-line {
      padding-top: 50px;
    }
  }

  @include breakpoint($md-only) {
    h3 {
      font-size: 2.6rem;
    }
  }
}

.callout-b {
  height: 320px;
  display: block;
  padding-top: 130px;
  padding-right: 10px;

  @include breakpoint($xs-only) {
    height: 200px;
    padding-top: 60px;
    padding-left: 30px;

    h3 {
      font-size: 2.6rem;
    }
  }

  h3 {
    text-align: left;
    padding-left: 40px;
    color: #fff;
    font-size: 3.4rem;
    float: left;
    margin-top: 0;
  }

  .cta-icon {
    font-size: 7rem;
    float: left;
    margin-top: 5px;
  }

  &:after {
    color: #fff;
    font-size: 20px;
    margin-top: 8px;
    margin-left: 5px;
  }

  &:hover {
    &:after {
      color: $wcm-bright-orange;
    }
  }

  @include breakpoint($sm) {
    &.visitor-information, &.education-and-training {
      padding-right: 100px;

      h3 {
        text-align: right;
        padding-right: 40px;
        float: right;
      }

      .cta-icon {
        float: right;
      }
    }

    &.patient-portal, &.research-areas {
      padding-left: 100px;
    }
  }
}

.multiply-effect {
  background-color: $wcm-red;
  background-blend-mode: multiply;
  background-size: auto, cover;
  background-position: center;
  background-repeat: repeat, no-repeat;

  &:hover {
    background-color: $wcm-yellow;
    background-blend-mode: multiply;
    text-decoration: none;
  }
}

// Callout Backgrounds
.homepage-callouts {
  .visitor-information {
    background-image: $diagonal-bg, url(../images/banners/booth.jpg);
  }

  .patient-portal {
    background-image: $diagonal-bg, url(../images/banners/group-of-doctors.jpg);
  }

  .education-and-training {
    background-image: $diagonal-bg, url(../images/banners/surgeons.jpg);
  }

  .research-areas {
    background-image: $diagonal-bg, url(../images/banners/beakers.jpg);
  }
}

.about-us-callouts {
  .our-history {
    background-image: $diagonal-bg, url(../images/banners/surgery-room.jpg);
  }

  .ways-to-give {
    background-image: $diagonal-bg, url(../images/banners/booth.jpg);
  }
}

.research-areas-callout {
  .our-research-areas {
    background-image: $diagonal-bg, url(../images/banners/research-areas.jpg);
  }

  .clinical-trials {
    background-image: $diagonal-bg, url(../images/banners/clinical-trials.jpg);
  }
}

.research-areas-callouts {
  .cancer-research {
    background-image: $diagonal-bg, url(../images/banners/cancer-research.jpg);
  }

  .clinical-research {
    background-image: $diagonal-bg, url(../images/banners/clinical-research.jpg);
  }

  .clinical-care-and-trauma {
    background-image: $diagonal-bg, url(../images/banners/clinical-care-and-trauma.jpg);
  }

  .metabolism-and-diabetes {
    background-image: $diagonal-bg, url(../images/banners/metabolism-and-diabetes.jpg);
  }

  .stem-cell-biology-and-regenerative-medicine {
    background-image: $diagonal-bg, url(../images/banners/stem-cell-biology-and-regenerative-medicine.jpg);
  }

  .surgical-innovation {
    background-image: $diagonal-bg, url(../images/banners/surgical-innovation.jpg);
  }

  .transplant-biology {
    background-image: $diagonal-bg, url(../images/banners/transplant-biology.jpg);
  }

  .vascular-biology {
    background-image: $diagonal-bg, url(../images/banners/vascular-biology.jpg);
  }

  .wound-healing {
    background-image: $diagonal-bg, url(../images/banners/wound-healing.jpg);
  }

  .outcome-research {
    background-image: $diagonal-bg, url(../images/banners/beakers.jpg);
  }
}

.education-and-training-callouts {
  .academy-of-educators {
    background-image: $diagonal-bg, url(../images/banners/academy-of-educators.jpg);
  }

  .medical-education {
    background-image: $diagonal-bg, url(../images/banners/medical-education.jpg);
  }

  .fellowships {
    background-image: $diagonal-bg, url(../images/banners/fellowships.jpg);
  }

  .residency-programs {
    background-image: $diagonal-bg, url(../images/banners/residency-programs.jpg);
  }

  .sail {
    background-image: $diagonal-bg, url(../images/banners/sail.jpg);
  }

  .medical-student-information {
    background-image: $diagonal-bg, url(../images/banners/medical-student-information.jpg);
  }
}

/* Home
----------------------------------------------------------*/

.page-home {
  .view-carousel {
    margin-top: 30px;
  }

  .mission-statement {
    font-size: 1rem;
    color: #000;
    padding: 20px;

    @include breakpoint($md) {
      font-size: 1.3rem;
      padding: 20px 80px;
    }

    @include breakpoint($lg) {
      font-size: 1.5rem;
      padding: 30px 120px;
    }

    .dividing-border {
      border-top: 2px solid $wcm-border-gray;
      width: 70%;
      margin: 0 auto;
      padding-bottom: 30px;
    }

    a {
      font-size: 0.8rem;

      @include breakpoint($md) {
        font-size: 1.2rem;
      }

      @include breakpoint($lg) {
        font-size: 1.4rem;
      }

      &:after {
        content: '\e80a';
        padding-left: 8px;
        font-size: 85%;
        text-decoration: none;
        position: relative;
        @include fontello-custom();
      }
    }
  }

  .container-fluid {
    padding: 0;
  }
}

// Surgical Services Listing
.pane-surgical-services-homepage-pane {
  background: url(../images/light-diagonal-pattern.png) repeat;
  padding: 40px 0;

  .view-header {
    width: 30%;
    float: left;

    @include breakpoint($xs-only) {
      width: 100%;
    }

    h2 {
      color: $wcm-bright-orange;
      font-size: 3.5rem;
      margin-top: 10px;
    }

    p {
      font-size: 1.4rem;
      color: #666;

      @include breakpoint($md) {
        font-size: 2rem;
      }
    }
  }

  .view-content {
    width: 65%;
    float: left;
    margin-left: 30px;

    @include breakpoint($xs-only) {
      width: 100%;
      margin-left: 0;
    }
  }

  .views-row {
    border-bottom: 1px solid $wcm-border-gray;

    a {
      padding: 5px 10px;
      font-size: 1.8rem;
      color: #666;
      display: block;
      position: relative;

      &:hover {
        text-decoration: none;
        color: $wcm-bright-orange;
        background: white;

        &:after {
          color: $wcm-bright-orange;
        }
      }

      &:after {
        content: '\e80a';
        color: $wcm-dark-orange;
        padding-left: 8px;
        font-size: 110%;
        text-decoration: none;
        position: absolute;
        top: 12px;
        right: 10px;
        @include fontello-custom();
      }
    }
  }
}

.pane-surgical-services-listing-pane, .simple-listing-pane {
  margin: 30px 0;

  .views-row {
    border-bottom: 1px solid $wcm-border-gray;

    a {
      padding: 12px 10px 7px;
      font-size: 2rem;
      color: $wcm-red;
      font-family: $wcm-bold;
      display: block;
      position: relative;

      &:hover {
        text-decoration: none;
        color: $wcm-bright-orange;
        background: $wcm-bg-gray;
      }

      &:after {
        content: '\e80a';
        color: $wcm-bright-orange;
        padding-left: 8px;
        font-size: 90%;
        text-decoration: none;
        position: absolute;
        top: 19px;
        right: 10px;
        @include fontello-custom();
      }
    }
  }
}

.surgical-service-dropdown-wrapper {
  margin: 25px 0;

  span.title {
    font-weight: bold;
    font-size: 1.2rem;
    display: inline-block;
    margin-bottom: 10px;
  }

  .form-select {
    max-width: 100%;
  }
}

.node-type-surgical-service .pane-node-body {
  border-bottom: 1px solid $wcm-border-gray;
  margin: 0 0 30px;
  padding-bottom: 20px;
}

.node-type-research-area .pane-node-body {
  border-bottom: 1px solid $wcm-border-gray;
  margin: 0 0 30px;
  padding-bottom: 20px;
}

.pane-patient-services-panel-pane-1 {
  border-bottom: 1px solid $wcm-border-gray;
  margin: 30px 0;
}

.pane-residency-programs-panel-pane-2 {
  border-bottom: 1px solid $wcm-border-gray;
  margin: 30px 0;
}

.pane-surgeons-related-surgeons-pane {
  border-bottom: 1px solid $wcm-border-gray;
  margin: 30px 0;
}

.pane-surgical-services-panel-pane-3 {
  border-bottom: 1px solid $wcm-border-gray;
  margin: 0 0 30px;

  .views-row {
    float: left;
    margin-right: 30px;
    padding-bottom: 20px;
  }

  a:before {
    content: '\e80e';
    padding-right: 8px;
    font-size: 2.6rem;
    text-decoration: none;
    position: relative;
    color: $wcm-bright-orange;
    @include fontello-custom();
  }

  a:hover {
    &:before {
      text-decoration: none;
    }
  }

  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

// Clinical Trials
.pane-clinical-trials-panel-pane-2 {
  border-bottom: 1px solid $wcm-border-gray;
  margin: 30px 0;
  padding-bottom: 20px;

  h3 {
    margin-bottom: 20px;
  }

  .views-row {
    margin-bottom: 15px;
  }
}

// Patient Services Listing
.view-patient-services {
  padding-bottom: 30px;

  h3 {
    margin-bottom: 20px;
  }
}

.pane-surgeons-our-surgeons-pane {
  border-bottom: 1px solid $wcm-border-gray;
  margin: 30px 0;
}

// Profiles
.profiles-grid {
  margin: 30px 0;

  &:after {
    content: "";
    display: table;
    clear: both;
  }

  .views-row {
    margin-bottom: 30px;
    padding-right: 10px;
    width: 50%;
    float: left;

    @include breakpoint($xs) {
      &:nth-of-type(2n+1) {
        clear: left;
      }
    }

    @include breakpoint($sm-only) {
      width: 33%;

      &:nth-of-type(3n+1) {
        clear: left;
      }
    }

    // Note - these are repeated due to the nth-of-type formula and the
    // nature of the mobile-first framework system.
    @include breakpoint($md-only) {
      width: 33%;

      &:nth-of-type(3n+1) {
        clear: left;
      }
    }

    @include breakpoint($lg) {
      width: 25%;

      &:nth-of-type(4n+1) {
        clear: left;
      }
    }
  }

  .profile-list-image img {
    width: 144px;
  }

  .display-name {
    font-weight: bold;
    font-size: 1.5rem;
    margin: 7px 0 4px;

    @include breakpoint($lg) {
      width: 175px;
    }
  }

  .profile-phone {
    font-size: 1.3rem;
    margin: 0 0 5px;
  }

  .profile-link {
    font-size: 1.3rem;
    margin: 1px 0;
  }
}

// About Us
.meet-our-team-wrapper {
  margin-top: 30px;

  .chairman-wrapper {
    .caption {
      display: inline-block;
      margin-top: 15px;
      font-size: 1.2rem;
      line-height: 1.8rem;
      font-style: italic;
      color: $wcm-med-gray;
    }

    @include breakpoint($xs-only) {
      text-align: center;
      margin-bottom: 30px;

      img {
        width: 160px;
      }
    }
  }

  @include breakpoint($md) {
    p {
      font-size: 20px;
    }
  }
}


// Meet Our Team
.pane-surgeons-panel-pane-4 {
  .views-exposed-form {
    margin-bottom: 40px;

    label {
      margin-bottom: 15px;
    }

    .form-select {
      max-width: 100%;

      @include breakpoint($md) {
        width: 400px;
      }
    }
  }

  .select2-selection__rendered {
    padding-right: 50px !important;
  }

  .ajax-progress-throbber {
    padding-top: 125px;
  }
}

// Residency Programs and Fellowships Surgical Services Panes
.view-residency-programs, .view-fellowships {
  padding-bottom: 20px;

  h3 {
    margin-bottom: 30px;
  }

  a:after {
    padding-left: 7px;
    padding-top: 3px;
    font-size: 60%;
    text-decoration: none;
    position: relative;
  }
}

.locations-container {
  border-bottom: 1px solid $wcm-border-gray;
  margin: 30px 0;

  h4 {
    margin: 20px 0;
    color: #777;
    font-size: 1.3rem;
    font-weight: 700;
    letter-spacing: 2px;
    text-transform: uppercase;
  }

  .addresses-wrapper {
    margin: 20px 0;
  }
}

// Surgical Service CTA
.surgical-service-cta-wrapper {
  margin: 20px 0;

  .phone-number {
    font-weight: bold;
    color: $wcm-dark-orange;
    font-size: 2.6rem;
    text-align: center;
    width: 100%;
    margin: 20px auto;
  }

  .website-url {
    margin: 20px auto;
  }
}

// Map and Locations
#map-canvas {
  width: 100%;
  height: 300px;
  display: block;
}

.addresses-wrapper {
  margin-top: 20px;
  margin-bottom: 20px;

  .address-location {
    width: 100%;
    margin: 0;
    clear: none;
    float: left;
    padding: 10px;
    cursor: pointer;

    &.active {
      background-color: #f2f2f2;
    }

    @include breakpoint($sm) {
      width: 50%;
    }

    @include breakpoint($lg) {
      width: 33%;
    }

    .icon-wrapper {
      float: left;
      width: 30px;
      padding: 2px 0;
      font-size: 2.5rem;
      line-height: 3.2rem;
      color: $wcm-bright-orange;
    }

    .practice-address {
      width: 90%;
      float: left;
      font-size: 1.3rem;

      @include breakpoint($md) {
        width: 87%;
      }
    }

    .practice-name {
      font-size: 1.4rem;
      padding-bottom: 5px;
      display: inline-block;
      font-weight: bold;
      a {
        color: #000;
      }
    }

    .address {
      width: 97%;
      float: left;
    }
  }

  .location-contact-details {
    display: none;
  }

  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

// Find a Physician

// Sidebar
.information-sidebar .find-a-physician-wrapper {
  border: 1px solid $wcm-border-gray;
  margin: 0 0 30px;
  padding: 20px;

  h3 {
    text-align: center;
    color: #777;
    font-size: 1.2rem;
    font-weight: 700;
    letter-spacing: 2px;
    text-transform: uppercase;
    margin: 5px 0 15px;
  }

  .form-item-keyword {
    margin-bottom: 25px;

    #autocomplete {
      text-align: left;
      left: 1px !important;
      border-color: $wcm-border-gray;

      div {
        padding: 3px;
      }

      div:hover {
        background: $wcm-bright-orange;
        color: #fff;
        cursor: pointer;
      }
    }
  }

  .form-text {
    color: $wcm-dark-orange;
    font-style: italic;

    &:focus {
      font-style: normal;
    }
  }

  .form-radios {
    margin: 0 0 20px 15px;
  }

  .form-submit {
    width: 60%;
    margin: 0 auto;
    display: block;
  }
}

.node-type-surgical-service .information-sidebar .find-a-physician-wrapper {
  margin: 30px 0 20px;
}

// Home

.page-home .find-a-physician-wrapper {
  background: $wcm-dark-orange;
  text-align: center;
  color: #fff;
  padding: 80px 0;

  h3 {
    font-size: 3.8rem;
    color: #fff;
    margin: 0 0 20px;
  }

  .form-item-keyword {
    width: 80%;
    margin: 30px auto 50px;

    @include breakpoint($md) {
      width: 440px;
    }

    input {
      font-size: 2rem;
      border: 3px solid $wcm-bright-orange;
      padding: 30px 20px;
    }

    #autocomplete {
      text-align: left;
      left: 2px !important;

      div {
        padding: 3px;
      }

      div:hover {
        background: $wcm-bright-orange;
        color: #fff;
        cursor: pointer;
      }
    }
  }

  .form-radios {
    margin: 30px 0;

    label {
      font-size: 1.8rem;
    }

    .form-radio {
      margin-left: -25px;
      margin-top: 7px;
    }
  }

  .form-submit {
    font-size: 1.8rem;
    border: 4px solid #fff;
    padding: 2px 70px;
  }
}

// Clinical Trials
.pane-clinical-trials-panel-pane-1 {
  .views-row {
    padding: 15px 0 20px;
    border-bottom: 1px solid $wcm-border-gray;

    .trial-title {
			padding-bottom: 10px;
			font-size: 17px;
    }

    .views-field {
			padding-bottom: 2px;
			.views-label {
				font-weight: 600;
			}
			.field-content {
				display: inline;
			}
		}

		.views-field-field-trial-source-link {
			padding-top: 10px;
    }

    h3 {
      margin-bottom: 15px;
      text-transform: lowercase;

      a {
        text-transform: capitalize;
      }
    }
  }

  .views-field-field-trial-key-eligibility {
    font-size: 1.3rem;
    margin-bottom: 20px;
  }

  .form-item-field-trial-primary-investigator-value-selective {
    .form-select {
      width: 100%;
      max-width: 100%;

      @include breakpoint($md) {
        width: 300px;
      }
    }
  }

  .form-item-field-trial-category-target-id-selective {
    .form-select {
      width: 100%;
      max-width: 100%;

      @include breakpoint($md) {
        width: 300px;
      }
    }
  }

  .views-exposed-form {
    margin-bottom: 40px;

    label {
      margin-bottom: 15px;
    }
  }

  .views-submit-button {
    margin-top: 22px;
  }
}

// FAP form styling.
.find-a-physician-wrapper {
  input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: #ddd;
  }

  input::-moz-placeholder { /* Firefox 19+ */
    color: #ddd;
  }

  input:-ms-input-placeholder { /* IE 10+ */
    color: #ddd;
  }

  input:-moz-placeholder { /* Firefox 18- */
    color: #ddd;
  }
}

// Forms
.webform-client-form {
  .webform-component-textfield, .webform-component-email {
    width: 50%;
    padding-right: 20px;
    float: left;
  }

  .form-textarea-wrapper {
    padding-right: 20px;
  }

  .form-textarea {
    max-width: 100%;
  }
}

// Video Callout
.video-callout-wrapper {
  position: relative;

  .video-overlay {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    width: 100%;
    height: 100%;
    background-color: $wcm-yellow;
    background-image: $diagonal-bg;
    background-blend-mode: multiply;
    opacity: 0.5;
  }

  .video-wrapper {
    display: block;
  }

  video {
    width: 100%;
    display: block;
  }

  .text-overlay {
    margin: 0 auto;
    text-align: center;
    transform: translate(-50%,-50%);
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 100;
    color: #fff;

    h2 {
      color: #fff;
      font-size: 3.8rem;
      margin-bottom: 80px;

      @include breakpoint($xs-only) {
        font-size: 2.6rem;
        margin-bottom: 20px;
      }
    }

    .btn--wcm {
      border-color: #fff;
      color: #fff;
      width: 160px;
    }
  }
}

// Weight Sorting
.view-id-weight {
  padding: 10px 60px;
}


// Clinical Trials homepage callout.
.pane-clinical-trials-panel-pane-3 {
  margin-bottom: 40px;
  float: left;

  h3 {
    text-align: center;
    font-size: 4rem;
    color: $wcm-bright-orange;
    padding: 30px 0;
  }

  .views-row {
    @include breakpoint($md) {
      width: 33%;
    }

    float: left;
    padding: 20px 30px;

    a {
      color: #777;

      &:hover {
        color: $wcm-bright-orange;
      }
    }
  }
}

// Simplified callout.
.homepage-clinical-trials {
  margin-bottom: 50px;
  float: left;
  text-align: center;

  h3 {
    text-align: center;
    font-size: 4rem;
    color: $wcm-bright-orange;
    padding: 30px 0;
  }

  p {
    @include breakpoint($md) {
      font-size: 1.7rem;
      padding: 0 50px 30px;
    }
  }
}


/* 2020-07-22 Mini Site Navigation */
.mini-site-menu-wrapper {
  margin-bottom: 10px;
}
.mini-site-title {
  font-family: $wcm-regular;
  font-weight: normal;
  font-size:50px;
  line-height: 60px;
  margin-top: 45px;
  margin-bottom: 25px;
  color: $wcm-red;
  text-align:center;
}

.form-select {
  max-width: none;
  height: 50px;
  border: 1px solid $wcm-border-gray;
  box-shadow: none;
  appearance: none;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3Cpath d='M207 381.5L12.7 187.1c-9.4-9.4-9.4-24.6 0-33.9l22.7-22.7c9.4-9.4 24.5-9.4 33.9 0l154.7 154 154.7-154c9.4-9.3 24.5-9.3 33.9 0l22.7 22.7c9.4 9.4 9.4 24.6 0 33.9L241 381.5c-9.4 9.3-24.6 9.3-34 0z' fill='%23cf4520'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 15px top 50%;
  background-size: 20px 20px;
  padding-right: 50px;
}

/* Mini Site - Who Should Attend */
.who-should-attend {
  background: url(/sites/default/files/styles/panopoly_image_original/public/who_should_attend4.jpg) top left no-repeat;
  background-size: 100% 100%;
}
.who-should-attend .fieldable-panels-pane {
  height: 240px;
  padding-top: .3em;
  @include breakpoint($sm) {
    margin: 0 auto;
    width: 660px;
    padding: 0;
  }
  @include breakpoint($md) {
    margin: 0 auto;
    width: 960px;
    height: 380px;
    padding: 0;
  }
}
.who-should-attend .fieldable-panels-pane div {
  margin: 0 1em;
  padding-top:.05em;
  @include breakpoint($sm) {
    width: 500px;
    padding-top: 10px;
  }
  @include breakpoint($md) {
    width: 500px;
    padding-top: 50px;
  }
}
.who-should-attend h2 {
  color: #000;
  //	text-transform: uppercase;
  font-weight: 400;
  margin-bottom: 25px;
  text-shadow: 0 0 1px #363636;
  @include breakpoint($sm) {
    font-size: 2.6rem;
  }
  @include breakpoint($md) {
    font-size: 3.2rem;
  }
}
.who-should-attend p {
  color: #000;
  text-shadow: 0 0 1px #363636;
  @include breakpoint($sm) {
    font-size: 1.7rem;
  }
  @include breakpoint($md) {
    font-size: 2.2rem;
  }
}
