/**
 * Webfonts and SVG Icon Set
 *
 */

@font-face {
  font-family: 'fontello-custom';
  src: url('../fonts/fontello/font/fontello.eot?72486420');
  src: url('../fonts/fontello/font/fontello.eot?72486420#iefix') format('embedded-opentype'),
       url('../fonts/fontello/font/fontello.woff?72486420') format('woff'),
       url('../fonts/fontello/font/fontello.ttf?72486420') format('truetype'),
       url('../fonts/fontello/font/fontello.svg?72486420#fontello') format('svg');
  font-style: normal;
  font-weight: normal;
}
