/**
 * Example Stub for creating WCMC Subthemes
 *
 */
/**
 * This file is used to customize the default Bootstrap core variables.
 * A list of options can be found here:
 * https://github.com/twbs/bootstrap-sass/blob/3.2-stable/assets/stylesheets/bootstrap/_variables.scss
 */
/* =WCM Colors and Fonts
----------------------------------------------------------*/
/* !WARNING!
When changing the colors below also change them in:
profiles/wcmc/modules/custom/panopoly_override/features/panopoly_wysiwyg.inc:53,
inside the panopoly_override_wysiwyg_editor_settings_alter() function.
JIRA Ticket: WEBENG-843
*/
/* =Scaffolding
----------------------------------------------------------*/
/* =Typography
----------------------------------------------------------*/
/* =Grid System
Padding between columns. Gets divided in half for the left and right.
----------------------------------------------------------*/
/* =Buttons
Define text, background and border color.
----------------------------------------------------------*/
/* =Forms
----------------------------------------------------------*/
/* =Pagination
----------------------------------------------------------*/
/**
 * Custom Mixins
 *
 */
/* =Breakpoint Utility
// https://medium.com/developing-with-sass/refactoring-my-simple-sass-breakpoint-mixin-71205dd6badd
----------------------------------------------------------*/
/* =Container Wrap
This is used when you can't add the class="container" markup,
just use this mixin on a wrapping element instead.
----------------------------------------------------------*/
/* =Fontello Icon Font
----------------------------------------------------------*/
/* =Dead Center within parent
----------------------------------------------------------*/
/* =Vertically Center
----------------------------------------------------------*/
/* =Button SubNav Style
----------------------------------------------------------*/
/* =Site-specific styles
----------------------------------------------------------*/
/**
 * Webfonts and SVG Icon Set
 *
 */
@font-face {
  font-family: 'fontello-custom';
  src: url("../fonts/fontello/font/fontello.eot?72486420");
  src: url("../fonts/fontello/font/fontello.eot?72486420#iefix") format("embedded-opentype"), url("../fonts/fontello/font/fontello.woff?72486420") format("woff"), url("../fonts/fontello/font/fontello.ttf?72486420") format("truetype"), url("../fonts/fontello/font/fontello.svg?72486420#fontello") format("svg");
  font-style: normal;
  font-weight: normal; }

/**
 * Custom Mixins
 *
 */
/* =Fontello Icon Font
----------------------------------------------------------*/
/* General
----------------------------------------------------------*/
/*.brand__lockup {
  margin-top: 30px;
  a img {
    height: 90px;
  }
}*/
/*#primary-nav .level-1 {
	width: 20%;
}*/
@media screen and (min-width: 992px) {
  #page #drawer-nav > .menu > li > ul > li {
    list-style-type: none;
    width: 33%;
    padding-right: 10px;
    min-height: 50px; } }

.site-name-wrapper {
  text-align: center; }
  @media screen and (min-width: 992px) {
    .site-name-wrapper {
      padding: 15px; } }

.youtube-callout-wrapper {
  text-align: center;
  border-top: 1px solid #ddd;
  padding: 15px 20px 10px;
  margin-top: 15px; }
  @media screen and (min-width: 768px) {
    .youtube-callout-wrapper {
      margin-top: 10px; } }
  .youtube-callout-wrapper p {
    font-size: 13px;
    line-height: 18px; }
  .youtube-callout-wrapper a.external-link:after {
    content: ''; }
  .youtube-callout-wrapper .cta-icon {
    width: 48px;
    height: 48px;
    fill: #fff; }

.footer-nav {
  width: 100%;
  margin-bottom: 20px; }
  .footer-nav .block-menu-block .content {
    column-count: 4;
    column-gap: 15px; }

.no-gutter {
  margin-right: 0;
  margin-left: 0; }

.no-gutter > [class*='col-'] {
  padding-right: 0;
  padding-left: 0; }

.cta-icon, .std-icon {
  color: #cf4520;
  fill: #e7751d; }

.btn-sml {
  background-color: #cf4520;
  color: #fff;
  border-radius: 10px;
  height: 30px;
  line-height: 28px;
  padding-bottom: 0;
  padding-top: 0;
  font-size: 13px; }

@media screen and (min-width: 768px) and (max-width: 991px) {
  #thumbnail-pager a > img {
    width: 90px;
    height: 36px; }
  .thumbnail-controller .pager {
    margin: 9px 0; } }

.callouts-a-wrapper {
  margin: 40px 0; }

.callout-a {
  height: 215px;
  display: block;
  padding-top: 75px;
  padding-right: 10px; }
  .callout-a h3 {
    text-align: left;
    padding-left: 40px;
    color: #fff;
    font-size: 3rem; }
  .callout-a:before {
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 25px 0 25px 20px;
    border-color: transparent transparent transparent #fff;
    position: absolute;
    top: 85px; }
  .callout-a.two-line {
    padding-top: 60px; }
  .callout-a:hover:before {
    border-color: transparent transparent transparent #fff; }
  @media screen and (min-width: 0) and (max-width: 766px) {
    .callout-a {
      height: 180px;
      padding-top: 60px; }
      .callout-a:before {
        top: 68px; }
      .callout-a h3 {
        font-size: 2.4rem; }
      .callout-a.two-line {
        padding-top: 50px; } }
  @media screen and (min-width: 992px) and (max-width: 1199px) {
    .callout-a h3 {
      font-size: 2.6rem; } }

.callout-b {
  height: 320px;
  display: block;
  padding-top: 130px;
  padding-right: 10px; }
  @media screen and (min-width: 0) and (max-width: 766px) {
    .callout-b {
      height: 200px;
      padding-top: 60px;
      padding-left: 30px; }
      .callout-b h3 {
        font-size: 2.6rem; } }
  .callout-b h3 {
    text-align: left;
    padding-left: 40px;
    color: #fff;
    font-size: 3.4rem;
    float: left;
    margin-top: 0; }
  .callout-b .cta-icon {
    font-size: 7rem;
    float: left;
    margin-top: 5px; }
  .callout-b:after {
    color: #fff;
    font-size: 20px;
    margin-top: 8px;
    margin-left: 5px; }
  .callout-b:hover:after {
    color: #e7751d; }
  @media screen and (min-width: 768px) {
    .callout-b.visitor-information, .callout-b.education-and-training {
      padding-right: 100px; }
      .callout-b.visitor-information h3, .callout-b.education-and-training h3 {
        text-align: right;
        padding-right: 40px;
        float: right; }
      .callout-b.visitor-information .cta-icon, .callout-b.education-and-training .cta-icon {
        float: right; }
    .callout-b.patient-portal, .callout-b.research-areas {
      padding-left: 100px; } }

.multiply-effect {
  background-color: #b31b1b;
  background-blend-mode: multiply;
  background-size: auto, cover;
  background-position: center;
  background-repeat: repeat, no-repeat; }
  .multiply-effect:hover {
    background-color: #ffc72c;
    background-blend-mode: multiply;
    text-decoration: none; }

.homepage-callouts .visitor-information {
  background-image: url(../images/light-diagonal-pattern-alpha.png), url(../images/banners/booth.jpg); }

.homepage-callouts .patient-portal {
  background-image: url(../images/light-diagonal-pattern-alpha.png), url(../images/banners/group-of-doctors.jpg); }

.homepage-callouts .education-and-training {
  background-image: url(../images/light-diagonal-pattern-alpha.png), url(../images/banners/surgeons.jpg); }

.homepage-callouts .research-areas {
  background-image: url(../images/light-diagonal-pattern-alpha.png), url(../images/banners/beakers.jpg); }

.about-us-callouts .our-history {
  background-image: url(../images/light-diagonal-pattern-alpha.png), url(../images/banners/surgery-room.jpg); }

.about-us-callouts .ways-to-give {
  background-image: url(../images/light-diagonal-pattern-alpha.png), url(../images/banners/booth.jpg); }

.research-areas-callout .our-research-areas {
  background-image: url(../images/light-diagonal-pattern-alpha.png), url(../images/banners/research-areas.jpg); }

.research-areas-callout .clinical-trials {
  background-image: url(../images/light-diagonal-pattern-alpha.png), url(../images/banners/clinical-trials.jpg); }

.research-areas-callouts .cancer-research {
  background-image: url(../images/light-diagonal-pattern-alpha.png), url(../images/banners/cancer-research.jpg); }

.research-areas-callouts .clinical-research {
  background-image: url(../images/light-diagonal-pattern-alpha.png), url(../images/banners/clinical-research.jpg); }

.research-areas-callouts .clinical-care-and-trauma {
  background-image: url(../images/light-diagonal-pattern-alpha.png), url(../images/banners/clinical-care-and-trauma.jpg); }

.research-areas-callouts .metabolism-and-diabetes {
  background-image: url(../images/light-diagonal-pattern-alpha.png), url(../images/banners/metabolism-and-diabetes.jpg); }

.research-areas-callouts .stem-cell-biology-and-regenerative-medicine {
  background-image: url(../images/light-diagonal-pattern-alpha.png), url(../images/banners/stem-cell-biology-and-regenerative-medicine.jpg); }

.research-areas-callouts .surgical-innovation {
  background-image: url(../images/light-diagonal-pattern-alpha.png), url(../images/banners/surgical-innovation.jpg); }

.research-areas-callouts .transplant-biology {
  background-image: url(../images/light-diagonal-pattern-alpha.png), url(../images/banners/transplant-biology.jpg); }

.research-areas-callouts .vascular-biology {
  background-image: url(../images/light-diagonal-pattern-alpha.png), url(../images/banners/vascular-biology.jpg); }

.research-areas-callouts .wound-healing {
  background-image: url(../images/light-diagonal-pattern-alpha.png), url(../images/banners/wound-healing.jpg); }

.research-areas-callouts .outcome-research {
  background-image: url(../images/light-diagonal-pattern-alpha.png), url(../images/banners/beakers.jpg); }

.education-and-training-callouts .academy-of-educators {
  background-image: url(../images/light-diagonal-pattern-alpha.png), url(../images/banners/academy-of-educators.jpg); }

.education-and-training-callouts .medical-education {
  background-image: url(../images/light-diagonal-pattern-alpha.png), url(../images/banners/medical-education.jpg); }

.education-and-training-callouts .fellowships {
  background-image: url(../images/light-diagonal-pattern-alpha.png), url(../images/banners/fellowships.jpg); }

.education-and-training-callouts .residency-programs {
  background-image: url(../images/light-diagonal-pattern-alpha.png), url(../images/banners/residency-programs.jpg); }

.education-and-training-callouts .sail {
  background-image: url(../images/light-diagonal-pattern-alpha.png), url(../images/banners/sail.jpg); }

.education-and-training-callouts .medical-student-information {
  background-image: url(../images/light-diagonal-pattern-alpha.png), url(../images/banners/medical-student-information.jpg); }

/* Home
----------------------------------------------------------*/
.page-home .view-carousel {
  margin-top: 30px; }

.page-home .mission-statement {
  font-size: 1rem;
  color: #000;
  padding: 20px; }
  @media screen and (min-width: 992px) {
    .page-home .mission-statement {
      font-size: 1.3rem;
      padding: 20px 80px; } }
  @media screen and (min-width: 1200px) {
    .page-home .mission-statement {
      font-size: 1.5rem;
      padding: 30px 120px; } }
  .page-home .mission-statement .dividing-border {
    border-top: 2px solid #dddddd;
    width: 70%;
    margin: 0 auto;
    padding-bottom: 30px; }
  .page-home .mission-statement a {
    font-size: 0.8rem; }
    @media screen and (min-width: 992px) {
      .page-home .mission-statement a {
        font-size: 1.2rem; } }
    @media screen and (min-width: 1200px) {
      .page-home .mission-statement a {
        font-size: 1.4rem; } }
    .page-home .mission-statement a:after {
      content: '\e80a';
      padding-left: 8px;
      font-size: 85%;
      text-decoration: none;
      position: relative;
      font-family: "fontello-custom";
      font-style: normal;
      font-weight: normal;
      speak: none;
      display: inline-block;
      text-decoration: inherit;
      text-align: center;
      /* opacity: .8; */
      /* For safety - reset parent styles, that can break glyph codes*/
      font-variant: normal;
      text-transform: none;
      /* fix buttons height, for twitter bootstrap */
      line-height: 1em;
      /* Animation center compensation - margins should be symmetric */
      /* remove if not needed */
      /* you can be more comfortable with increased icons size */
      /* font-size: 120%; */
      /* Font smoothing. That was taken from TWBS */
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      /* Uncomment for 3D effect */
      /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */ }

.page-home .container-fluid {
  padding: 0; }

.pane-surgical-services-homepage-pane {
  background: url(../images/light-diagonal-pattern.png) repeat;
  padding: 40px 0; }
  .pane-surgical-services-homepage-pane .view-header {
    width: 30%;
    float: left; }
    @media screen and (min-width: 0) and (max-width: 766px) {
      .pane-surgical-services-homepage-pane .view-header {
        width: 100%; } }
    .pane-surgical-services-homepage-pane .view-header h2 {
      color: #e7751d;
      font-size: 3.5rem;
      margin-top: 10px; }
    .pane-surgical-services-homepage-pane .view-header p {
      font-size: 1.4rem;
      color: #666; }
      @media screen and (min-width: 992px) {
        .pane-surgical-services-homepage-pane .view-header p {
          font-size: 2rem; } }
  .pane-surgical-services-homepage-pane .view-content {
    width: 65%;
    float: left;
    margin-left: 30px; }
    @media screen and (min-width: 0) and (max-width: 766px) {
      .pane-surgical-services-homepage-pane .view-content {
        width: 100%;
        margin-left: 0; } }
  .pane-surgical-services-homepage-pane .views-row {
    border-bottom: 1px solid #dddddd; }
    .pane-surgical-services-homepage-pane .views-row a {
      padding: 5px 10px;
      font-size: 1.8rem;
      color: #666;
      display: block;
      position: relative; }
      .pane-surgical-services-homepage-pane .views-row a:hover {
        text-decoration: none;
        color: #e7751d;
        background: white; }
        .pane-surgical-services-homepage-pane .views-row a:hover:after {
          color: #e7751d; }
      .pane-surgical-services-homepage-pane .views-row a:after {
        content: '\e80a';
        color: #cf4520;
        padding-left: 8px;
        font-size: 110%;
        text-decoration: none;
        position: absolute;
        top: 12px;
        right: 10px;
        font-family: "fontello-custom";
        font-style: normal;
        font-weight: normal;
        speak: none;
        display: inline-block;
        text-decoration: inherit;
        text-align: center;
        /* opacity: .8; */
        /* For safety - reset parent styles, that can break glyph codes*/
        font-variant: normal;
        text-transform: none;
        /* fix buttons height, for twitter bootstrap */
        line-height: 1em;
        /* Animation center compensation - margins should be symmetric */
        /* remove if not needed */
        /* you can be more comfortable with increased icons size */
        /* font-size: 120%; */
        /* Font smoothing. That was taken from TWBS */
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        /* Uncomment for 3D effect */
        /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */ }

.pane-surgical-services-listing-pane, .simple-listing-pane {
  margin: 30px 0; }
  .pane-surgical-services-listing-pane .views-row, .simple-listing-pane .views-row {
    border-bottom: 1px solid #dddddd; }
    .pane-surgical-services-listing-pane .views-row a, .simple-listing-pane .views-row a {
      padding: 12px 10px 7px;
      font-size: 2rem;
      color: #b31b1b;
      font-family: "1898Sans-Bold", sans-serif;
      display: block;
      position: relative; }
      .pane-surgical-services-listing-pane .views-row a:hover, .simple-listing-pane .views-row a:hover {
        text-decoration: none;
        color: #e7751d;
        background: #f7f7f7; }
      .pane-surgical-services-listing-pane .views-row a:after, .simple-listing-pane .views-row a:after {
        content: '\e80a';
        color: #e7751d;
        padding-left: 8px;
        font-size: 90%;
        text-decoration: none;
        position: absolute;
        top: 19px;
        right: 10px;
        font-family: "fontello-custom";
        font-style: normal;
        font-weight: normal;
        speak: none;
        display: inline-block;
        text-decoration: inherit;
        text-align: center;
        /* opacity: .8; */
        /* For safety - reset parent styles, that can break glyph codes*/
        font-variant: normal;
        text-transform: none;
        /* fix buttons height, for twitter bootstrap */
        line-height: 1em;
        /* Animation center compensation - margins should be symmetric */
        /* remove if not needed */
        /* you can be more comfortable with increased icons size */
        /* font-size: 120%; */
        /* Font smoothing. That was taken from TWBS */
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        /* Uncomment for 3D effect */
        /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */ }

.surgical-service-dropdown-wrapper {
  margin: 25px 0; }
  .surgical-service-dropdown-wrapper span.title {
    font-weight: bold;
    font-size: 1.2rem;
    display: inline-block;
    margin-bottom: 10px; }
  .surgical-service-dropdown-wrapper .form-select {
    max-width: 100%; }

.node-type-surgical-service .pane-node-body {
  border-bottom: 1px solid #dddddd;
  margin: 0 0 30px;
  padding-bottom: 20px; }

.node-type-research-area .pane-node-body {
  border-bottom: 1px solid #dddddd;
  margin: 0 0 30px;
  padding-bottom: 20px; }

.pane-patient-services-panel-pane-1 {
  border-bottom: 1px solid #dddddd;
  margin: 30px 0; }

.pane-residency-programs-panel-pane-2 {
  border-bottom: 1px solid #dddddd;
  margin: 30px 0; }

.pane-surgeons-related-surgeons-pane {
  border-bottom: 1px solid #dddddd;
  margin: 30px 0; }

.pane-surgical-services-panel-pane-3 {
  border-bottom: 1px solid #dddddd;
  margin: 0 0 30px; }
  .pane-surgical-services-panel-pane-3 .views-row {
    float: left;
    margin-right: 30px;
    padding-bottom: 20px; }
  .pane-surgical-services-panel-pane-3 a:before {
    content: '\e80e';
    padding-right: 8px;
    font-size: 2.6rem;
    text-decoration: none;
    position: relative;
    color: #e7751d;
    font-family: "fontello-custom";
    font-style: normal;
    font-weight: normal;
    speak: none;
    display: inline-block;
    text-decoration: inherit;
    text-align: center;
    /* opacity: .8; */
    /* For safety - reset parent styles, that can break glyph codes*/
    font-variant: normal;
    text-transform: none;
    /* fix buttons height, for twitter bootstrap */
    line-height: 1em;
    /* Animation center compensation - margins should be symmetric */
    /* remove if not needed */
    /* you can be more comfortable with increased icons size */
    /* font-size: 120%; */
    /* Font smoothing. That was taken from TWBS */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* Uncomment for 3D effect */
    /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */ }
  .pane-surgical-services-panel-pane-3 a:hover:before {
    text-decoration: none; }
  .pane-surgical-services-panel-pane-3:after {
    content: "";
    display: table;
    clear: both; }

.pane-clinical-trials-panel-pane-2 {
  border-bottom: 1px solid #dddddd;
  margin: 30px 0;
  padding-bottom: 20px; }
  .pane-clinical-trials-panel-pane-2 h3 {
    margin-bottom: 20px; }
  .pane-clinical-trials-panel-pane-2 .views-row {
    margin-bottom: 15px; }

.view-patient-services {
  padding-bottom: 30px; }
  .view-patient-services h3 {
    margin-bottom: 20px; }

.pane-surgeons-our-surgeons-pane {
  border-bottom: 1px solid #dddddd;
  margin: 30px 0; }

.profiles-grid {
  margin: 30px 0; }
  .profiles-grid:after {
    content: "";
    display: table;
    clear: both; }
  .profiles-grid .views-row {
    margin-bottom: 30px;
    padding-right: 10px;
    width: 50%;
    float: left; }
    @media screen and (max-width: 767px) {
      .profiles-grid .views-row:nth-of-type(2n+1) {
        clear: left; } }
    @media screen and (min-width: 768px) and (max-width: 991px) {
      .profiles-grid .views-row {
        width: 33%; }
        .profiles-grid .views-row:nth-of-type(3n+1) {
          clear: left; } }
    @media screen and (min-width: 992px) and (max-width: 1199px) {
      .profiles-grid .views-row {
        width: 33%; }
        .profiles-grid .views-row:nth-of-type(3n+1) {
          clear: left; } }
    @media screen and (min-width: 1200px) {
      .profiles-grid .views-row {
        width: 25%; }
        .profiles-grid .views-row:nth-of-type(4n+1) {
          clear: left; } }
  .profiles-grid .profile-list-image img {
    width: 144px; }
  .profiles-grid .display-name {
    font-weight: bold;
    font-size: 1.5rem;
    margin: 7px 0 4px; }
    @media screen and (min-width: 1200px) {
      .profiles-grid .display-name {
        width: 175px; } }
  .profiles-grid .profile-phone {
    font-size: 1.3rem;
    margin: 0 0 5px; }
  .profiles-grid .profile-link {
    font-size: 1.3rem;
    margin: 1px 0; }

.meet-our-team-wrapper {
  margin-top: 30px; }
  .meet-our-team-wrapper .chairman-wrapper .caption {
    display: inline-block;
    margin-top: 15px;
    font-size: 1.2rem;
    line-height: 1.8rem;
    font-style: italic;
    color: #666666; }
  @media screen and (min-width: 0) and (max-width: 766px) {
    .meet-our-team-wrapper .chairman-wrapper {
      text-align: center;
      margin-bottom: 30px; }
      .meet-our-team-wrapper .chairman-wrapper img {
        width: 160px; } }
  @media screen and (min-width: 992px) {
    .meet-our-team-wrapper p {
      font-size: 20px; } }

.pane-surgeons-panel-pane-4 .views-exposed-form {
  margin-bottom: 40px; }
  .pane-surgeons-panel-pane-4 .views-exposed-form label {
    margin-bottom: 15px; }
  .pane-surgeons-panel-pane-4 .views-exposed-form .form-select {
    max-width: 100%; }
    @media screen and (min-width: 992px) {
      .pane-surgeons-panel-pane-4 .views-exposed-form .form-select {
        width: 400px; } }

.pane-surgeons-panel-pane-4 .select2-selection__rendered {
  padding-right: 50px !important; }

.pane-surgeons-panel-pane-4 .ajax-progress-throbber {
  padding-top: 125px; }

.view-residency-programs, .view-fellowships {
  padding-bottom: 20px; }
  .view-residency-programs h3, .view-fellowships h3 {
    margin-bottom: 30px; }
  .view-residency-programs a:after, .view-fellowships a:after {
    padding-left: 7px;
    padding-top: 3px;
    font-size: 60%;
    text-decoration: none;
    position: relative; }

.locations-container {
  border-bottom: 1px solid #dddddd;
  margin: 30px 0; }
  .locations-container h4 {
    margin: 20px 0;
    color: #777;
    font-size: 1.3rem;
    font-weight: 700;
    letter-spacing: 2px;
    text-transform: uppercase; }
  .locations-container .addresses-wrapper {
    margin: 20px 0; }

.surgical-service-cta-wrapper {
  margin: 20px 0; }
  .surgical-service-cta-wrapper .phone-number {
    font-weight: bold;
    color: #cf4520;
    font-size: 2.6rem;
    text-align: center;
    width: 100%;
    margin: 20px auto; }
  .surgical-service-cta-wrapper .website-url {
    margin: 20px auto; }

#map-canvas {
  width: 100%;
  height: 300px;
  display: block; }

.addresses-wrapper {
  margin-top: 20px;
  margin-bottom: 20px; }
  .addresses-wrapper .address-location {
    width: 100%;
    margin: 0;
    clear: none;
    float: left;
    padding: 10px;
    cursor: pointer; }
    .addresses-wrapper .address-location.active {
      background-color: #f2f2f2; }
    @media screen and (min-width: 768px) {
      .addresses-wrapper .address-location {
        width: 50%; } }
    @media screen and (min-width: 1200px) {
      .addresses-wrapper .address-location {
        width: 33%; } }
    .addresses-wrapper .address-location .icon-wrapper {
      float: left;
      width: 30px;
      padding: 2px 0;
      font-size: 2.5rem;
      line-height: 3.2rem;
      color: #e7751d; }
    .addresses-wrapper .address-location .practice-address {
      width: 90%;
      float: left;
      font-size: 1.3rem; }
      @media screen and (min-width: 992px) {
        .addresses-wrapper .address-location .practice-address {
          width: 87%; } }
    .addresses-wrapper .address-location .practice-name {
      font-size: 1.4rem;
      padding-bottom: 5px;
      display: inline-block;
      font-weight: bold; }
      .addresses-wrapper .address-location .practice-name a {
        color: #000; }
    .addresses-wrapper .address-location .address {
      width: 97%;
      float: left; }
  .addresses-wrapper .location-contact-details {
    display: none; }
  .addresses-wrapper:after {
    content: "";
    display: table;
    clear: both; }

.information-sidebar .find-a-physician-wrapper {
  border: 1px solid #dddddd;
  margin: 0 0 30px;
  padding: 20px; }
  .information-sidebar .find-a-physician-wrapper h3 {
    text-align: center;
    color: #777;
    font-size: 1.2rem;
    font-weight: 700;
    letter-spacing: 2px;
    text-transform: uppercase;
    margin: 5px 0 15px; }
  .information-sidebar .find-a-physician-wrapper .form-item-keyword {
    margin-bottom: 25px; }
    .information-sidebar .find-a-physician-wrapper .form-item-keyword #autocomplete {
      text-align: left;
      left: 1px !important;
      border-color: #dddddd; }
      .information-sidebar .find-a-physician-wrapper .form-item-keyword #autocomplete div {
        padding: 3px; }
      .information-sidebar .find-a-physician-wrapper .form-item-keyword #autocomplete div:hover {
        background: #e7751d;
        color: #fff;
        cursor: pointer; }
  .information-sidebar .find-a-physician-wrapper .form-text {
    color: #cf4520;
    font-style: italic; }
    .information-sidebar .find-a-physician-wrapper .form-text:focus {
      font-style: normal; }
  .information-sidebar .find-a-physician-wrapper .form-radios {
    margin: 0 0 20px 15px; }
  .information-sidebar .find-a-physician-wrapper .form-submit {
    width: 60%;
    margin: 0 auto;
    display: block; }

.node-type-surgical-service .information-sidebar .find-a-physician-wrapper {
  margin: 30px 0 20px; }

.page-home .find-a-physician-wrapper {
  background: #cf4520;
  text-align: center;
  color: #fff;
  padding: 80px 0; }
  .page-home .find-a-physician-wrapper h3 {
    font-size: 3.8rem;
    color: #fff;
    margin: 0 0 20px; }
  .page-home .find-a-physician-wrapper .form-item-keyword {
    width: 80%;
    margin: 30px auto 50px; }
    @media screen and (min-width: 992px) {
      .page-home .find-a-physician-wrapper .form-item-keyword {
        width: 440px; } }
    .page-home .find-a-physician-wrapper .form-item-keyword input {
      font-size: 2rem;
      border: 3px solid #e7751d;
      padding: 30px 20px; }
    .page-home .find-a-physician-wrapper .form-item-keyword #autocomplete {
      text-align: left;
      left: 2px !important; }
      .page-home .find-a-physician-wrapper .form-item-keyword #autocomplete div {
        padding: 3px; }
      .page-home .find-a-physician-wrapper .form-item-keyword #autocomplete div:hover {
        background: #e7751d;
        color: #fff;
        cursor: pointer; }
  .page-home .find-a-physician-wrapper .form-radios {
    margin: 30px 0; }
    .page-home .find-a-physician-wrapper .form-radios label {
      font-size: 1.8rem; }
    .page-home .find-a-physician-wrapper .form-radios .form-radio {
      margin-left: -25px;
      margin-top: 7px; }
  .page-home .find-a-physician-wrapper .form-submit {
    font-size: 1.8rem;
    border: 4px solid #fff;
    padding: 2px 70px; }

.pane-clinical-trials-panel-pane-1 .views-row {
  padding: 15px 0 20px;
  border-bottom: 1px solid #dddddd; }
  .pane-clinical-trials-panel-pane-1 .views-row .trial-title {
    padding-bottom: 10px;
    font-size: 17px; }
  .pane-clinical-trials-panel-pane-1 .views-row .views-field {
    padding-bottom: 2px; }
    .pane-clinical-trials-panel-pane-1 .views-row .views-field .views-label {
      font-weight: 600; }
    .pane-clinical-trials-panel-pane-1 .views-row .views-field .field-content {
      display: inline; }
  .pane-clinical-trials-panel-pane-1 .views-row .views-field-field-trial-source-link {
    padding-top: 10px; }
  .pane-clinical-trials-panel-pane-1 .views-row h3 {
    margin-bottom: 15px;
    text-transform: lowercase; }
    .pane-clinical-trials-panel-pane-1 .views-row h3 a {
      text-transform: capitalize; }

.pane-clinical-trials-panel-pane-1 .views-field-field-trial-key-eligibility {
  font-size: 1.3rem;
  margin-bottom: 20px; }

.pane-clinical-trials-panel-pane-1 .form-item-field-trial-primary-investigator-value-selective .form-select {
  width: 100%;
  max-width: 100%; }
  @media screen and (min-width: 992px) {
    .pane-clinical-trials-panel-pane-1 .form-item-field-trial-primary-investigator-value-selective .form-select {
      width: 300px; } }

.pane-clinical-trials-panel-pane-1 .form-item-field-trial-category-target-id-selective .form-select {
  width: 100%;
  max-width: 100%; }
  @media screen and (min-width: 992px) {
    .pane-clinical-trials-panel-pane-1 .form-item-field-trial-category-target-id-selective .form-select {
      width: 300px; } }

.pane-clinical-trials-panel-pane-1 .views-exposed-form {
  margin-bottom: 40px; }
  .pane-clinical-trials-panel-pane-1 .views-exposed-form label {
    margin-bottom: 15px; }

.pane-clinical-trials-panel-pane-1 .views-submit-button {
  margin-top: 22px; }

.find-a-physician-wrapper input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #ddd; }

.find-a-physician-wrapper input::-moz-placeholder {
  /* Firefox 19+ */
  color: #ddd; }

.find-a-physician-wrapper input:-ms-input-placeholder {
  /* IE 10+ */
  color: #ddd; }

.find-a-physician-wrapper input:-moz-placeholder {
  /* Firefox 18- */
  color: #ddd; }

.webform-client-form .webform-component-textfield, .webform-client-form .webform-component-email {
  width: 50%;
  padding-right: 20px;
  float: left; }

.webform-client-form .form-textarea-wrapper {
  padding-right: 20px; }

.webform-client-form .form-textarea {
  max-width: 100%; }

.video-callout-wrapper {
  position: relative; }
  .video-callout-wrapper .video-overlay {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    width: 100%;
    height: 100%;
    background-color: #ffc72c;
    background-image: url(../images/light-diagonal-pattern-alpha.png);
    background-blend-mode: multiply;
    opacity: 0.5; }
  .video-callout-wrapper .video-wrapper {
    display: block; }
  .video-callout-wrapper video {
    width: 100%;
    display: block; }
  .video-callout-wrapper .text-overlay {
    margin: 0 auto;
    text-align: center;
    transform: translate(-50%, -50%);
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 100;
    color: #fff; }
    .video-callout-wrapper .text-overlay h2 {
      color: #fff;
      font-size: 3.8rem;
      margin-bottom: 80px; }
      @media screen and (min-width: 0) and (max-width: 766px) {
        .video-callout-wrapper .text-overlay h2 {
          font-size: 2.6rem;
          margin-bottom: 20px; } }
    .video-callout-wrapper .text-overlay .btn--wcm {
      border-color: #fff;
      color: #fff;
      width: 160px; }

.view-id-weight {
  padding: 10px 60px; }

.pane-clinical-trials-panel-pane-3 {
  margin-bottom: 40px;
  float: left; }
  .pane-clinical-trials-panel-pane-3 h3 {
    text-align: center;
    font-size: 4rem;
    color: #e7751d;
    padding: 30px 0; }
  .pane-clinical-trials-panel-pane-3 .views-row {
    float: left;
    padding: 20px 30px; }
    @media screen and (min-width: 992px) {
      .pane-clinical-trials-panel-pane-3 .views-row {
        width: 33%; } }
    .pane-clinical-trials-panel-pane-3 .views-row a {
      color: #777; }
      .pane-clinical-trials-panel-pane-3 .views-row a:hover {
        color: #e7751d; }

.homepage-clinical-trials {
  margin-bottom: 50px;
  float: left;
  text-align: center; }
  .homepage-clinical-trials h3 {
    text-align: center;
    font-size: 4rem;
    color: #e7751d;
    padding: 30px 0; }
  @media screen and (min-width: 992px) {
    .homepage-clinical-trials p {
      font-size: 1.7rem;
      padding: 0 50px 30px; } }

/* 2020-07-22 Mini Site Navigation */
.mini-site-menu-wrapper {
  margin-bottom: 10px; }

.mini-site-title {
  font-family: "1898Sans-Regular", sans-serif;
  font-weight: normal;
  font-size: 50px;
  line-height: 60px;
  margin-top: 45px;
  margin-bottom: 25px;
  color: #b31b1b;
  text-align: center; }

.form-select {
  max-width: none;
  height: 50px;
  border: 1px solid #dddddd;
  box-shadow: none;
  appearance: none;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3Cpath d='M207 381.5L12.7 187.1c-9.4-9.4-9.4-24.6 0-33.9l22.7-22.7c9.4-9.4 24.5-9.4 33.9 0l154.7 154 154.7-154c9.4-9.3 24.5-9.3 33.9 0l22.7 22.7c9.4 9.4 9.4 24.6 0 33.9L241 381.5c-9.4 9.3-24.6 9.3-34 0z' fill='%23cf4520'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 15px top 50%;
  background-size: 20px 20px;
  padding-right: 50px; }

/* Mini Site - Who Should Attend */
.who-should-attend {
  background: url(/sites/default/files/styles/panopoly_image_original/public/who_should_attend4.jpg) top left no-repeat;
  background-size: 100% 100%; }

.who-should-attend .fieldable-panels-pane {
  height: 240px;
  padding-top: .3em; }
  @media screen and (min-width: 768px) {
    .who-should-attend .fieldable-panels-pane {
      margin: 0 auto;
      width: 660px;
      padding: 0; } }
  @media screen and (min-width: 992px) {
    .who-should-attend .fieldable-panels-pane {
      margin: 0 auto;
      width: 960px;
      height: 380px;
      padding: 0; } }

.who-should-attend .fieldable-panels-pane div {
  margin: 0 1em;
  padding-top: .05em; }
  @media screen and (min-width: 768px) {
    .who-should-attend .fieldable-panels-pane div {
      width: 500px;
      padding-top: 10px; } }
  @media screen and (min-width: 992px) {
    .who-should-attend .fieldable-panels-pane div {
      width: 500px;
      padding-top: 50px; } }

.who-should-attend h2 {
  color: #000;
  font-weight: 400;
  margin-bottom: 25px;
  text-shadow: 0 0 1px #363636; }
  @media screen and (min-width: 768px) {
    .who-should-attend h2 {
      font-size: 2.6rem; } }
  @media screen and (min-width: 992px) {
    .who-should-attend h2 {
      font-size: 3.2rem; } }

.who-should-attend p {
  color: #000;
  text-shadow: 0 0 1px #363636; }
  @media screen and (min-width: 768px) {
    .who-should-attend p {
      font-size: 1.7rem; } }
  @media screen and (min-width: 992px) {
    .who-should-attend p {
      font-size: 2.2rem; } }
